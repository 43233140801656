import React, { useEffect } from "react";
import { API } from "aws-amplify";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";
import { useSelector, useDispatch } from "react-redux";
import { Constants } from "../../../config/constants";
import config from "../../../config/aws-exports";
import ClockGray from "../../../assets/svgjs/ClockGray";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import ProgressBar from "../../../modules/ProgressBar/ProgressBar";
import { useNavigate } from "react-router-dom";
import UserHeader from "../../Header/UserHeader/UserHeader";
import dashboardStyle from "./Dashboard.module.scss";
import "./dashboard.scss";
import { FaBars } from "react-icons/fa";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .slick-arrow": {
      color: "black",
    },
    "& .slick-prev:before": {
      color: "black",
      fontSize: 27,
    },
    "& .slick-next:before": {
      color: "black",
      fontSize: 27,
    },
    "& .MuiGrid-root": {},
    "& .MuiGrid-item": {},
    "& .MuiGrid-grid-xs-6": {},
  },
  paperAnnounce: {
    textAlign: "start",
    color: theme.palette.text.secondary,
    width: "100%",
  },
  paperEvents: {
    textAlign: "start",
    color: theme.palette.text.secondary,
    width: "100%",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  paper: {
    "& .MuiDialog-paperWidthSm": {
      height: "80%",
      width: "90%",
      padding: "50px 0px 0px",
      "@media (min-height: 800px)": {
        height: "72rem",
      },
    },
  },
  content: {
    ".MuiDialogContent-root": {
      overflowY: "hidden",
    },
  },
  mainGrid: {
    width: "100%",
    margin: 0,
    "@media (max-width: 520px)": {
      "& > div": {
        padding: "15px!important",
      },
    },
  },
  listContents: {
    marginLeft: "1rem",
    justifyContent: "left",
    "& > p": {
      overflow: "auto",
    },
  },
  modalHeading: {
    position: "absolute",
    top: "0",
    left: "30px",
    maxWidth: "800px",
    "@media (max-width: 520px)": {
      padding: "16px 0px",
    },
  },
}));

const Dashboard = ({ handleToggleSidebar }) => {
  let navigate = useNavigate();

  let userDetails = useSelector(authData);

  const dispatch = useDispatch();
  useEffect(() => {
    get();

    setClass("/dashboard");
  }, []);

  function setClass(val) {
    let sdata = { ...userDetails };
    sdata.sideactive = val;
    dispatch(awsSignIn(sdata));
  }
  async function get() {
    let userdata = { ...userDetails };

    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userdata.eid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      let response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_USER_PROGRESS,
        bodyParam
      );

      let sortdata = response;
      let sortdata1 = response.bpdata;

      if (sortdata1.length > 0) {
        for (var i = 0; i < sortdata1.length; i++) {
          if (sortdata1[i].tcourses.length > 0) {
            const filteredItems = sortdata1[i].tcourses.filter(
              (item) => item.selected === false && item.telective === true
            );

            if (filteredItems.length > 0) {
              filteredItems.forEach((item) => {
                const indexToRemove = sortdata.bpdata[i].tcourses.indexOf(item);
                if (indexToRemove !== -1) {
                  sortdata.bpdata[i].tcourses.splice(indexToRemove, 1);
                }
              });
            }
          }
        }
      }
      userdata.data = response;

      dispatch(awsSignIn(userdata));
    } catch (error) {
      console.error(error);
    }
  }

  const classes = useStyles();
  var settingsAllPrograms = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    draggable: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  function titleclick(data, PValue) {
    let sdata = { ...userDetails };

    sdata.curprgcou = { ...data };
    sdata.curprgcou.pid = PValue.pid;
    sdata.curprgcou.bpid = PValue.bpid;
    sdata.curprgcou.ptitle = PValue.ptitle;

    dispatch(awsSignIn(sdata));

    navigate("/course");
  }

  function programlist() {
    let data = [];

    if (userDetails.data != undefined) {
      if (userDetails.data.bpdata != undefined) {
        data = [...userDetails.data.bpdata];

        console.log("data", data)
      }
    }

    return (
      <Grid container spacing={9} className={classes.mainGrid}>
        {data.map((PValue, index) => (
          <Grid item xs={12} sm={12} md={12} lg={12} key={index}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h3
                style={{
                  marginLeft: "0px",
                  color: "#E35F14",
                  fontWeight: "bold",
                  fontSize: "19px",
                }}
                className={dashboardStyle.gridHeaderCourse}
              >
                {PValue.ptitle}
              </h3>
            </div>
            <Slider {...settingsAllPrograms} style={{ width: "100%" }}>
              {PValue.tcourses.map((Value) => (
                <div
                  key={Value.tid}
                  onClick={() => {
                    titleclick(Value, PValue);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <Card
                    className={dashboardStyle.card}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      alt=""
                      className={dashboardStyle.imageCard}
                      src={`https://${Constants.DOMAIN
                        }/${config.aws_org_id.toLowerCase()}-resources/images/topic-images/${Value.tid
                        }.png`}
                    />
                    <div className={dashboardStyle.progressbar}>
                      <ProgressBar color="orange" percent={Value.per} />
                    </div>
                    <p className={dashboardStyle.topicNameDashboard}>
                      {Value.ttitle}
                    </p>

                    <div>
                      <p style={{ marginTop: "100px" }}>
                        <span style={{ marginLeft: "10px" }}>
                          <ClockGray
                            className={dashboardStyle.clock}
                            cls1="cls1"
                            cls2="cls2"
                          />
                        </span>
                        <span
                          style={{
                            fontSize: "12px",
                            color: "rgba(0, 0, 0, 0.6)",
                            marginLeft: "-10px",
                            marginBottom: "-10px",
                          }}
                        >
                          {Value.tdur} weeks
                        </span>
                      </p>
                    </div>

                  </Card>
                </div>
              ))}
            </Slider>
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <main>
      <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
        <FaBars />
      </div>
      <div>
        <UserHeader />
        <div style={{ height: "100vh" }}>
          <div className={classes.root}>{programlist()}</div>
        </div>
      </div>
    </main>
  );
};

export default Dashboard;
