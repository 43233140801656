import React, { useEffect, useState } from "react";
import moment from "moment";
import { Tab } from "semantic-ui-react";
import InstructorsCard from "../../../modules/Cards/Instructors/InstructorsCard";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";
import { useNavigate } from "react-router-dom";
import "../../../modules/Tabs/Tabs.scss";
import "./ProgramInfo.scss";
import Rupee from "../../../assets/svgjs/Rupee";
import PdfIcon from "../../../assets/svgjs/Pdf";
import Video from "../../../assets/svgjs/Video";
import Audio from "../../../assets/svgjs/Audio";
import ImageIcon from "@material-ui/icons/Image";
import PlaceholderParagraph from "../../../modules/Placeholder/PlaceholderParagraph";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import ClockOrange from "../../../assets/svgjs/ClockOrange";
import Announcements from "../../../assets/svgjs/Announcements";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ListItem from "@material-ui/core/ListItem";
import Paper from "@material-ui/core/Paper";
import ReactPlayer from "react-player";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import { FaBars } from "react-icons/fa";
import UserHeader from "../../Header/UserHeader/UserHeader";
import config from "../../../config/aws-exports";
import { API } from "aws-amplify";
import { Constants } from "../../../config/constants";
import { DialogTitle, useMediaQuery } from "@material-ui/core";
import progIbfo from "./ProgramInfo.module.scss";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiAccordionDetails-root": {
      display: "list-item",
      padding: "8px 16px 16px",
    },
  },
  paper: {
    "& .MuiDialog-paperWidthSm": {
      height: "80%",
      width: "90%",
      padding: "50px 0px 0px",
      "@media (min-height: 800px)": {
        height: "72rem",
      },
    },
  },
  content: {
    ".MuiDialogContent-root": {
      overflowY: "hidden",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(13),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));
export default function ProgramInfo({ handleToggleSidebar }) {
  const classes = useStyles();
  const [activeIndex, setActiveIndex] = useState(0);
  const [programsJSON, setProgramsJSON] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [announcements, setAnnouncements] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = useState(false);
  const [obj, setObj] = useState({});

  let navigate = useNavigate();

  let userDetails = useSelector(authData);
  const dispatch = useDispatch();
  let a = userDetails.pgcdactive;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    set();
    getProgramsDetailsPost();
    getAnnouncementLoad();
  }, []);
  async function set() {

    setActiveIndex(a.a);
  }
  async function getProgramsDetailsPost() {
    const bodyParam = {
      body: { oid: config.aws_org_id, pid: a.pid, bpid: a.bpid },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PROGRAM,
        bodyParam
      );
      const programsJSON = response;

      setProgramsJSON(programsJSON);
      setIsLoading(false);
    } catch (error) {
      console.error("getCategoryError", error);
    }

  }

  async function getAnnouncementLoad() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        bpid: [a.bpid],
        rtype: "getall",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.POST_ANNOUNCEMENT,
        bodyParam
      );
      const AnnouncementsJSON = response.Response;
      setAnnouncements(AnnouncementsJSON);

    } catch (error) {
      console.error(error);
    }
  }

  function continueLearning() {
    let pdata = userDetails.data.bpdata;
    let sdata = { ...userDetails };
    for (let i = 0; i < pdata.length; i++) {
      if (a.bpid == pdata[i].bpid) {
        sdata.curprg = pdata[i];
      }
    }

    dispatch(awsSignIn(sdata));

    navigate("/MyPrograms/programs");
  }

  const handleChange = (panel) => (isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleClickOpen = (full) => {
    setOpen(true);

    setObj(full);
  };
  const handleClose = () => {
    setOpen(false);
  };

  function programNameAnc(prog) {
    for (let i = 0; i < userDetails.data.bpdata.length; i++) {

      if (announcements[prog].tid === userDetails.data.bpdata[i].bpid) {
        return <h2>{userDetails.data.bpdata[i].ptitle}</h2>;
      }
    }
  }
  function renderObjects(curObject) {
    let objType = curObject.type;

    switch (objType) {
      case "video":
        return VideoView(curObject); // loadVideoView(curObject);
      case "audio":
        return AudioView(curObject); // loadAudioView(curObject);
      case "pdf":
        return PdfView(curObject);
      case "image":
        return imageView(curObject); // loadMultimediaView(curObject);
      case "html":
        return WebView(curObject); // loadMultimediaView(curObject);
      case "Interactivity" || "interactivity":
        return WebView(curObject); // loadInteractivityView(curObject);

      case "vimeo":
        return VimeoView(curObject); // loadVimeoView(curObject);
      case "youtube":
        return youtubeView(curObject); // loadYoutubeView(curObject);
      default:
        return null;
    }
  }
  function VideoView(curObject) {
    return (
      <div
        className="player-wrapper"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <ReactPlayer
          url={`https://${Constants.DOMAIN
            }/${config.aws_org_id.toLowerCase()}-resources/images/announcement-images/${curObject.mfname
            }`}
          controls={true}
          width="100%"
          config={{
            file: {
              attributes: {
                controlsList: "nodownload", //<- this is the important bit
              },
            },
          }}
        />
      </div>
    );
  }

  function AudioView(curObject) {
    return (
      <div>
        <div
          className="player-wrapper"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <ReactPlayer
            url={`https://${Constants.DOMAIN
              }/${config.aws_org_id.toLowerCase()}-resources/images/announcement-images/${curObject.mfname
              }`}
            controls={true}
          />
        </div>
      </div>
    );
  }

  function VimeoView(curObject) {
    return (
      <div>
        <div
          className="player-wrapper"
          style={{
            display: "flex",
            justifyContent: "center",
            width: "1000px",
            height: "600px",
          }}
        >
          <ReactPlayer
            url={
              curObject.otype !== "vimeo"
                ? `https://youtu.be/${curObject.embeddedcode}`
                : `https://player.vimeo.com/video/${curObject.embeddedcode}?title=0&loop=0`
            }
            controls={true}
            width="100%"
            height="100%"
          />
        </div>
      </div>
    );
  }

  function youtubeView(curObject) {
    return (
      <div
        className="youtube-wrapper"
        style={{
          display: "flex",
          justifyContent: "center",
          height: "660px",
          width: "1100px",
          alignItems: "center",
        }}
      >
        <ReactPlayer
          url={`https://youtu.be/${curObject.embeddedcode}`}
          controls={true}
          width="100%"
          height="100%"
          style={{ paddingTop: "10px", paddingBottom: "5px" }}
        />
      </div>
    );
  }

  function PdfView(curObject) {

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          overflowX: "hidden",
        }}
      >
        <iframe
          frameBorder="0"
          src={`https://${Constants.DOMAIN
            }/${config.aws_org_id.toLowerCase()}-resources/images/announcement-images/${curObject.mfname
            }`}
          height="100%"
          width="100%"
          title="pdf"
        />
      </div>
    );
  }
  function imageView(curObject) {

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          overflowX: "hidden",
        }}
      >
        <iframe
          frameBorder="0"
          src={`https://${Constants.DOMAIN
            }/${config.aws_org_id.toLowerCase()}-resources/images/announcement-images/${curObject.mfname
            }`}
          height="100%"
          width="100%"
          title="pdf"
        />
      </div>
    );
  }
  function WebView(curObject) {

    return (
      <div
        style={{
          display: "flex",
          width: "1100px",

          height: "660px",
          justifyContent: "center",
        }}
      >
        <iframe
          frameBorder="0"
          src={`https://${Constants.DOMAIN
            }/${config.aws_org_id.toLowerCase()}-resources/images/announcement-images/${curObject.mfname
            }`}
          width={"100%"}
          height="100%"
          title="WebView"
        />
      </div>
    );
  }
  const panes = [
    {
      menuItem: "Overview",
      render: () => (
        <Tab.Pane className={progIbfo.overview}>
          {isLoading ? (
            <PlaceholderParagraph />
          ) : (
            <p
              className={progIbfo.p_text}
              dangerouslySetInnerHTML={{ __html: programsJSON.poverview }}
            ></p>
          )}
          <div></div>
          <div>
            <h2 className={progIbfo.h2_margin}>Program features</h2>
            {isLoading ? (
              <div style={{ width: "100%", overflow: "hidden" }}>
                <PlaceholderParagraph />
              </div>
            ) : (
              <p
                className={progIbfo.p_text}
                dangerouslySetInnerHTML={{ __html: programsJSON.pfeatures }}
              ></p>
            )}
          </div>
          <hr />
          <div>
            <h2 className={progIbfo.h2_margin}>Program outcomes</h2>
            {isLoading ? (
              <PlaceholderParagraph />
            ) : (
              <p
                className={progIbfo.p_text}
                dangerouslySetInnerHTML={{ __html: programsJSON.poutcomes }}
              ></p>
            )}
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Instructors",
      render: () => (
        <Tab.Pane>
          <div>
            <div>
              <div className="instructors">
                {programsJSON.pinstructors.map((item, idx) => (
                  <div>
                    <div className="instructors__h1">
                      <h2
                        style={{
                          marginLeft: "20px",
                          marginTop: "20px",
                          fontSize: "18px",
                        }}
                      >
                        {item.title}
                      </h2>
                    </div>
                    <div>
                      <div className="card-container">
                        {item.value.map(
                          ({ name, designation, org, pic }, idx) => (
                            <InstructorsCard
                              key={idx}
                              name={name}
                              designation={designation}
                              org={org}
                              pic={pic}
                              pid={programsJSON.pid}
                            />
                          )
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Curriculum",
      render: () => (
        <Tab.Pane>
          {programsJSON
            ? programsJSON.pcurriculum.map(
              ({ tlabel, tduration, ttitle, tunits, tid }) => (
                <div className={progIbfo.pcurriculum}>
                  <div className={progIbfo.courses_container}>
                    <div className={progIbfo.ID_container}>
                      <div
                        className={progIbfo.image_container}
                        style={{
                          backgroundSize: 'cover',
                          backgroundImage: `url('https://${Constants.DOMAIN
                            }/${config.aws_org_id.toLowerCase()}-resources/images/topic-images/${tid}.png')`,
                        }}
                      ></div>
                      <div className={progIbfo.details_container}>
                        <div>
                          <h3
                            style={{ fontSize: "15px", marginBottom: "10px" }}
                          >
                            {tlabel}
                          </h3>
                          <h2
                            style={{ fontSize: "15px", fontWeight: "bold" }}
                          >
                            {ttitle}
                          </h2>
                        </div>
                        <div>
                          <p style={{ marginTop: "-12px" }}>
                            <span>
                              <ClockOrange
                                className={progIbfo.clock_size__s}
                                cls1={progIbfo.cls1_s}
                                cls2={progIbfo.cls2_s}
                              />
                            </span>
                            <span style={{ fontSize: "small" }}>
                              {tduration} Weeks
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    {tunits.map((units) => (
                      <div>
                        <p className={progIbfo.tunits}>{units}</p>
                      </div>
                    ))}
                  </div>
                </div>
              )
            )
            : null}
        </Tab.Pane>
      ),
    },
    {
      menuItem: "FAQs",
      render: () => (
        <Tab.Pane>
          {programsJSON
            ? programsJSON.pfaq.map(({ title, questions }, idx) => (
              <div className={classes.root} key={idx}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>
                      <h2 style={{ fontSize: "16px", fontWeight: "bold" }}>
                        {title}
                      </h2>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {questions.map(({ ques, ans }, id) => (
                      <div className={classes.root} key={id}>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <h3 style={{ fontSize: "15px" }}>{ques}</h3>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography style={{ fontSize: "14px" }}>
                              <p
                                className={progIbfo.p_text}
                                dangerouslySetInnerHTML={{ __html: ans }}
                              ></p>
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    ))}
                  </AccordionDetails>
                </Accordion>
              </div>
            ))
            : null}
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Syllabus",
      render: () => (
        <Tab.Pane>
          <iframe
            style={{ height: "700px" }}
            src={`https://${Constants.DOMAIN
              }/${config.aws_org_id.toLowerCase()}-resources/pdf/${programsJSON.bpid
              }.pdf`}
            height="600"
            width="100%"
          ></iframe>

        </Tab.Pane>
      ),
    },
    {
      menuItem: "Announcements",
      render: () => (
        <Tab.Pane>
          <Paper className={classes.paperAnnounce}>
            <Typography component="list" variant="h1">
              {isLoading ? <Skeleton /> : null}
            </Typography>
            <Typography component="list" variant="h1">
              {isLoading ? <Skeleton /> : null}
            </Typography>
            <Typography component="list" variant="h1">
              {isLoading ? <Skeleton /> : null}
            </Typography>
            <Typography component="list" variant="h1">
              {isLoading ? <Skeleton /> : null}
            </Typography>

            {announcements.length !== 0 ? (
              <div>
                {Object.keys(announcements).map((prog, idx) => (
                  <Accordion
                    key={idx}
                    expanded={expanded === `${idx}`}
                    onChange={handleChange(`${idx}`)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography className={classes.heading}>
                        {programNameAnc(prog)}
                      </Typography>
                    </AccordionSummary>
                    {announcements[prog].json.map((full) => (
                      <div>
                        {full.type === "text" ? (
                          <>
                            <ListItem alignItems="flex-start">
                              <Announcements
                                className={progIbfo.announcementIcon}
                              />{" "}
                              <ListItemText
                                style={{
                                  marginLeft: "20px",
                                  justifyContent: "flex-start",
                                  overflow: "auto",
                                }}
                                primary={
                                  <p
                                    style={{
                                      fontSize: "small",
                                      marginTop: "-20px",
                                    }}
                                  >
                                    {moment(full.sdate * 1000).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </p>
                                }
                                secondary={
                                  <React.Fragment>
                                    <p
                                      style={{
                                        fontSize: "medium",
                                        marginTop: "10px",
                                      }}
                                    >
                                      {full.title}
                                    </p>
                                    <br />
                                    <Typography
                                      component="span"
                                      variant="body2"
                                      color="textPrimary"
                                    >

                                      <p
                                        style={{
                                          fontSize: "medium",
                                          whiteSpace: "pre-line",
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html: full.description,
                                        }}
                                      ></p>
                                    </Typography>
                                  </React.Fragment>
                                }
                              />
                            </ListItem>
                            <hr style={{ marginBottom: "20px" }} />
                          </>
                        ) : null || full.type === "pdf" ? (
                          <>
                            <ListItem alignItems="flex-start">
                              <Announcements
                                className={progIbfo.announcementIcon}
                              />{" "}
                              <ListItemText
                                style={{
                                  marginLeft: "20px",
                                  cursor: "pointer",
                                  justifyContent: "flex-start",
                                  overflow: "auto",
                                }}
                                primary={
                                  <p
                                    style={{
                                      fontSize: "small",
                                      marginTop: "-20px",
                                    }}
                                  >
                                    {moment(full.sdate * 1000).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </p>
                                }
                                secondary={
                                  <React.Fragment>
                                    <p
                                      style={{
                                        fontSize: "medium",
                                        marginTop: "10px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                    >
                                      <span>{full.title}</span>
                                      <span
                                        onClick={() => {
                                          handleClickOpen(full);
                                        }}
                                      >
                                        <PdfIcon className="pdfAct" />
                                      </span>
                                    </p>
                                    <p>
                                      <Typography
                                        component="span"
                                        variant="body2"
                                        color="textPrimary"
                                      >


                                        <p
                                          style={{
                                            fontSize: "medium",
                                            whiteSpace: "pre-line",
                                          }}
                                          dangerouslySetInnerHTML={{
                                            __html: full.description,
                                          }}
                                        ></p>
                                      </Typography>
                                    </p>
                                    <br />
                                  </React.Fragment>
                                }
                              />
                            </ListItem>
                            <hr style={{ marginBottom: "20px" }} />
                          </>
                        ) : null || full.type === "web" ? (
                          <>
                            <ListItem alignItems="flex-start">
                              <Announcements
                                className={progIbfo.announcementIcon}
                              />
                              <ListItemText
                                style={{
                                  marginLeft: "20px",
                                  justifyContent: "flex-start",
                                  overflow: "auto",
                                }}
                                primary={
                                  <p
                                    style={{
                                      fontSize: "small",
                                      marginTop: "-20px",
                                    }}
                                  >
                                    {moment(full.sdate * 1000).format(
                                      "DD/MM/YYYY "
                                    )}
                                  </p>
                                }
                                secondary={
                                  <React.Fragment>
                                    <p
                                      style={{
                                        fontSize: "medium",
                                        marginTop: "10px",
                                      }}
                                    >
                                      {full.title}
                                    </p>
                                    <br />
                                    <Typography
                                      component="span"
                                      variant="body2"
                                      color="textPrimary"
                                    >
                                      <p style={{ fontSize: "medium" }}>
                                        {full.webtext}
                                      </p>
                                      <p style={{ fontSize: "medium" }}>
                                        {" "}
                                        Web url :{" "}
                                        <a href={`${full.weburl}`}>
                                          {full.weburl}
                                        </a>
                                      </p>
                                    </Typography>
                                  </React.Fragment>
                                }
                              />
                            </ListItem>
                            <hr style={{ marginBottom: "20px" }} />
                          </>
                        ) : null || full.type === "video" ? (
                          <>
                            <ListItem alignItems="flex-start">
                              <Announcements
                                className={progIbfo.announcementIcon}
                              />{" "}
                              <ListItemText
                                style={{
                                  marginLeft: "20px",
                                  cursor: "pointer",
                                  justifyContent: "flex-start",
                                  overflow: "auto",
                                }}
                                primary={
                                  <p
                                    style={{
                                      fontSize: "small",
                                      marginTop: "-20px",
                                    }}
                                  >
                                    {moment(full.sdate * 1000).format(
                                      "DD/MM/YYYY  "
                                    )}
                                  </p>
                                }
                                secondary={
                                  <React.Fragment>
                                    <p
                                      style={{
                                        fontSize: "medium",
                                        marginTop: "10px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                    >
                                      <span>{full.title}</span>
                                      <span
                                        onClick={() => {
                                          handleClickOpen(full);
                                        }}
                                      >
                                        <Video
                                          className="video-size"
                                          cls1="cls-1--v"
                                          cls2="cls-2--v"
                                        />
                                      </span>
                                    </p>
                                    <p>
                                      <Typography
                                        component="span"
                                        variant="body2"
                                        color="textPrimary"
                                      >

                                        <p
                                          style={{
                                            fontSize: "medium",
                                            whiteSpace: "pre-line",
                                          }}
                                          dangerouslySetInnerHTML={{
                                            __html: full.description,
                                          }}
                                        ></p>
                                      </Typography>
                                    </p>
                                    <br />
                                  </React.Fragment>
                                }
                              />
                            </ListItem>
                            <hr style={{ marginBottom: "20px" }} />
                          </>
                        ) : null || full.type === "audio" ? (
                          <>
                            <ListItem alignItems="flex-start">
                              <Announcements
                                className={progIbfo.announcementIcon}
                              />
                              <ListItemText
                                style={{
                                  marginLeft: "20px",
                                  cursor: "pointer",
                                  justifyContent: "flex-start",
                                  overflow: "auto",
                                }}
                                primary={
                                  <p
                                    style={{
                                      fontSize: "small",
                                      marginTop: "-20px",
                                    }}
                                  >
                                    {moment(full.sdate * 1000).format(
                                      "DD/MM/YYYY  "
                                    )}
                                  </p>
                                }
                                secondary={
                                  <React.Fragment>
                                    <p
                                      style={{
                                        fontSize: "medium",
                                        marginTop: "10px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                    >
                                      <span>{full.title}</span>
                                      <span
                                        onClick={() => {
                                          handleClickOpen(full);
                                        }}
                                      >
                                        <Audio
                                          className="video-size"
                                          cls1="cls-1--v"
                                          cls2="cls-2--v"
                                        />
                                      </span>
                                    </p>
                                    <p>
                                      <Typography
                                        component="span"
                                        variant="body2"
                                        color="textPrimary"
                                      >

                                        <p
                                          style={{
                                            fontSize: "medium",
                                            whiteSpace: "pre-line",
                                          }}
                                          dangerouslySetInnerHTML={{
                                            __html: full.description,
                                          }}
                                        ></p>
                                      </Typography>
                                    </p>
                                    <br />
                                  </React.Fragment>
                                }
                              />
                            </ListItem>
                            <hr style={{ marginBottom: "20px" }} />
                          </>
                        ) : null || full.type === "image" ? (
                          <>
                            <ListItem alignItems="flex-start">
                              <Announcements
                                className={progIbfo.announcementIcon}
                              />{" "}
                              <ListItemText
                                style={{
                                  marginLeft: "20px",
                                  cursor: "pointer",
                                  justifyContent: "flex-start",
                                  overflow: "auto",
                                }}
                                primary={
                                  <p
                                    style={{
                                      fontSize: "small",
                                      marginTop: "-20px",
                                    }}
                                  >
                                    {moment(full.sdate * 1000).format(
                                      "DD/MM/YYYY "
                                    )}
                                  </p>
                                }
                                secondary={
                                  <React.Fragment>
                                    <p
                                      style={{
                                        fontSize: "medium",
                                        marginTop: "10px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                    >
                                      <span>{full.title}</span>
                                      <span>
                                        <ImageIcon
                                          style={{
                                            fill: "#f18121",
                                            height: "2.5rem",
                                          }}
                                          onClick={() => {
                                            handleClickOpen(full);
                                          }}
                                        />
                                      </span>
                                    </p>
                                    <p>
                                      <Typography
                                        component="span"
                                        variant="body2"
                                        color="textPrimary"
                                      >


                                        <p
                                          style={{
                                            fontSize: "medium",
                                            whiteSpace: "pre-line",
                                          }}
                                          dangerouslySetInnerHTML={{
                                            __html: full.description,
                                          }}
                                        ></p>
                                      </Typography>
                                    </p>
                                    <br />
                                  </React.Fragment>
                                }
                              />
                            </ListItem>
                            <hr style={{ marginBottom: "20px" }} />
                          </>
                        ) : null}
                      </div>
                    ))}
                  </Accordion>
                ))}
              </div>
            ) : (
              <p style={{ fontSize: "medium", textAlign: "center" }}>
                No Announcements
              </p>
            )}
            <div className={progIbfo.announcmentDivider} />
          </Paper>
          <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            className={classes.paper}
          >
            <div>
              <DialogTitle
                style={{
                  position: "absolute",
                  top: "0",
                  left: "30px",
                  maxWidth: "800px",
                  padding: "1rem 0rem",
                }}
              >
                {obj.title}
              </DialogTitle>
              <IconButton
                style={{ position: "absolute", top: "0", right: "0" }}
                component="span"
                onClick={() => {
                  handleClose();
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <DialogContent className={classes.content}>
              {renderObjects(obj)}
            </DialogContent>
            <DialogActions></DialogActions>
          </Dialog>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Academic Schedule",
      render: () => (
        <Tab.Pane>
          <iframe
            style={{ height: "700px" }}
            src={`https://${Constants.DOMAIN
              }/${config.aws_org_id.toLowerCase()}-resources/pdf/academic/${programsJSON.bpid
              }.pdf`}
            height="600"
            width="100%"
          ></iframe>

        </Tab.Pane>
      ),
    },
  ];

  let handleTabChange = (e, { activeIndex }) => setActiveIndex(activeIndex);

  return (
    <main>
      <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
        <FaBars />
      </div>
      <UserHeader />
      <div className={progIbfo.overview}>
        <div className={progIbfo.overview__h1}>
          <Typography component="div" key="h2" variant="h2">
            {isLoading ? (
              <Skeleton />
            ) : (
              <h1>
                <strong>{programsJSON.pname}</strong>
              </h1>
            )}
          </Typography>
        </div>
        <div className={progIbfo.overview__card}>
          <div className={progIbfo.overview__cardContent}>
            <div className={progIbfo.content}>
              <div className={progIbfo.content__details}>
                <div className={progIbfo.align_self}>

                </div>

                {
                  programsJSON.pinfo != undefined && (
                    <div>
                      <div className={progIbfo.items} style={{
                        display: "grid",

                        gridTemplateColumns: "auto 20%"
                      }}>

                        {
                          programsJSON.pinfo.duration && (<div>
                            <ClockOrange
                              className={progIbfo.clock_size}
                              cls1={progIbfo.cls_1}
                              cls2={progIbfo.cls_2}
                            />

                            {isLoading ? <Skeleton /> : programsJSON.pinfo.duration}

                          </div>)
                        }
                        {
                          programsJSON.pinfo.format && (<div>
                            Format:{" "}

                            {isLoading ? <Skeleton /> : programsJSON.pinfo.format}

                          </div>)
                        }

                      </div>
                      <div className={progIbfo.items} style={{
                        display: "grid",
                        gridTemplateColumns: "auto 20%",
                        marginBottom: "2rem"
                      }}>

                        {
                          programsJSON.pinfo.price && (<div>


                            <Rupee className={progIbfo.rupee_size} />

                            {isLoading ? <Skeleton /> : programsJSON.pinfo.price}

                          </div>)
                        }

                        {
                          programsJSON.pinfo.credits && (<div>
                            Credits:{" "}

                            {isLoading ? <Skeleton /> : programsJSON.pinfo.credits}

                          </div>)
                        }


                      </div>
                    </div>)

                }

                <div className={progIbfo.align_self}>
                  {isLoading ? (
                    <Skeleton />
                  ) : (
                    <button
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        width: "200px",
                        borderRadius: "6px",
                        height: "30px",
                      }}
                      className={progIbfo.btn_color}
                      onClick={continueLearning}
                    >
                      Continue Learning
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          {isLoading ? (
            <Skeleton variant="rect" width="100%">
              <div style={{ paddingTop: "57%" }} />
            </Skeleton>
          ) : (
            <div className={progIbfo.overview__cardImage}>
              <img
                src={`https://${Constants.DOMAIN
                  }/${config.aws_org_id.toLowerCase()}-resources/images/program-images/${programsJSON.pid
                  }.png`}
                alt=""
              />
            </div>
          )}
        </div>
        <div className={progIbfo.tabMargin}>
          <Tab
            panes={panes}
            activeIndex={activeIndex}
            onTabChange={handleTabChange}
          />
        </div>
      </div>
    </main >
  );
}
